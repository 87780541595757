import {useEffect, useState} from "react";
import Flag from "react-world-flags";
import calcComb from "../../services/Helpers/CalculateNumOfComb";
import {currencyFormatter, formatNumber4} from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import ResultsBall from "./ResultsBall";

export default function ResultsBoxAllBets(props) {
  const [localRecords, setLocalRecords] = useState([]);
  const [localRecordsWinSum, setLocalRecordsWinSum] = useState(0);
  const [localIsWin, setLocalIsWin] = useState(false);

  let truncateDecimals = function (number, digits) {
    var multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    return truncatedNum / multiplier;
  };

  useEffect(() => {
    if (props.indexArray.length === 35 && localRecords.length === 0 &&
    JSON.parse(storage.get('roundId')) === props.roundNumber &&
    props.noRecord !== true) {
        setLocalRecords(props.indexArray);
    }
  }, [localRecords.length, props.indexArray, props.noRecord, props.roundNumber])

  useEffect(() => {
    if (localRecords.length === 0 && JSON.parse(storage.get('roundId')) === props.roundNumber) {
      // calculation of win for both, alllbet mybet
      let winHandler = [];
      props.balls.forEach((ball, index) => {
        if (localRecords.length === 35 && 
            localRecords[localRecords.indexOf(ball)] === ball) {
              // console.log("TEST")
        } else if (JSON.parse(storage.get('roundId')) === props.roundNumber &&
          props.indexArray[props.indexArray.indexOf(ball)] === ball) {
            if (typeof props.indexArrayQuota[props.indexArray.indexOf(ball)] !== 'undefined') {
              winHandler.push({
                winBall: ball,
                indexBall: props.indexArray.indexOf(ball),
                quota: props.indexArray.indexOf(ball) === 34 ? 1 : props.indexArrayQuota[props.indexArray.indexOf(ball)]
              });
            }
        }
      });

      if (winHandler.length >= 6) {
        setLocalIsWin(true);
        const numOfSumComb = calcComb(6, props.balls.length);
        let stakePerCombination = (props.currency !== props.userCurrencyCode ? props.baseStake : props.stake) / numOfSumComb;
        winHandler.sort(function(a, b) {
          return parseFloat(a.indexBall) - parseFloat(b.indexBall);
        });
        let ticketWinSum = 0;
        let localHandlerOfWinCombination = {
          lastState: 0,
          finalState: 0
        };

        stakePerCombination = truncateDecimals(stakePerCombination, 4);
        // stakePerCombination = parseFloat(stakePerCombination.toFixed(4));
        winHandler.forEach((item, index) => {
          if (index >= 5) {
            localHandlerOfWinCombination.finalState = calcComb(6, (index + 1)) - localHandlerOfWinCombination.lastState;
            ticketWinSum += stakePerCombination * localHandlerOfWinCombination.finalState * winHandler[index].quota;
            localHandlerOfWinCombination.lastState = calcComb(6, (index + 1));
          }
        });
        ticketWinSum = parseFloat(ticketWinSum.toFixed(props.net.noOfDecimals));
        if (typeof ticketWinSum === 'number') {
          if (props.currency !== props.userCurrencyCode) {
            let EXCHANGED = (ticketWinSum * parseFloat(storage.get('currency_exchange_rate')));
            if(EXCHANGED > parseFloat(storage.get('maxWin'))) {
              // console.log('ALL BETS WIN CHECK MAX!' + EXCHANGED + " vs " + parseFloat(storage.get('maxWin')))
              setLocalRecordsWinSum(parseFloat(storage.get('maxWin')));
            } else {
              setLocalRecordsWinSum(EXCHANGED);
            }
          } else {
            if(ticketWinSum > parseFloat(storage.get('maxWin'))) {
              setLocalRecordsWinSum(parseFloat(storage.get('maxWin')));
              // console.log('ALL BETS WIN CHECK MAX!' + ticketWinSum + " vs " + parseFloat(storage.get('maxWin')))
            } else {
              setLocalRecordsWinSum(ticketWinSum);
            }
          }
        }
      } else {
        setLocalIsWin(false);
        setLocalRecordsWinSum(0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.baseStake, props.indexArray, localRecords, props.roundNumber, props.noRecord, props.balls, props.indexArrayQuota, props.stake])

  return (
    <div className={localIsWin === true ? "results-box winner" : "results-box"}>
      {!props.withoutHeader ? (
        <div className="results-box-heder">
          <div className="results-user-avatar">
            <img src="/avatars/avatar-1.png" alt={props.username} />
          </div>
          <div className="results-user-name">{props.username}</div>
          <div className="results-user-flag">
            {(storage.get('flagPreview') === false ? null : <Flag code={props.flag} height="10" width="14" />)}
          </div>
        </div>
      ) : null}
      <div className="results-balls-holder">
        {props.balls.map((ball, index) => {
          return (
            <ResultsBall key={index} label={ball} 
            winBall={
              (( localRecords.length === 35 && 
                 localRecords[localRecords.indexOf(ball)] === ball)
              || 
              (JSON.parse(storage.get('roundId')) === props.roundNumber &&
                props.indexArray[props.indexArray.indexOf(ball)] === ball) 
                  ? true : false)
              }
          />);
        })}
      </div>
      <div className="results-box-footer">
        <div className="results-box-footer-left">
          <div className="results-box-footer-info">
            <label>{label.t("round")}:</label>
            {props.roundNumber}
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("stake")}:</label>
            {/* {props.currency !== props.userCurrencyCode ?
             sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(parseFloat(props.baseStake.toFixed(2)) * parseFloat(storage.get('currency_exchange_rate'), "###,###")):
             currencyFormatter(parseFloat(props.baseStake.toFixed(2)) * parseFloat(storage.get('currency_exchange_rate')) ) :
             sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(props.stake, "###,###"):
             currencyFormatter(props.stake)} */}
                {sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(Number(props.stake), "###,###"):
                currencyFormatter(props.stake)}
            &nbsp;
            <span>{props.currency !== props.userCurrencyCode ? props.userCurrencyCode : props.currency}</span>
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("win")}:</label>
            <span className="results-box-footer-info-win"> {
              sessionStorage.getItem('amountFormat') === '###,###' ?
              currencyFormatter(localRecordsWinSum, '###,###') :
              currencyFormatter(localRecordsWinSum,) 
             } </span>
            <span>{props.currency !== props.userCurrencyCode ? props.userCurrencyCode : props.currency}</span>
          </div>
        </div>
        <div className="results-box-footer-right">
          <span className="me-1">{props.date}</span>
          <span>{props.time}</span>
        </div>
      </div>
    </div>
  );
}
