
import {io} from "socket.io-client";
import config from "../../config/config";
import storage from "../Storage/Storage";

class TransportAbstract {

  constructor(platformParams, serverKeepaliveTimeout, secure_conn = true) {

    this.DEBUG = window.location.search.includes("debug=true");
    this.dlog = (arg) => {
      if(this.DEBUG === true) {
        console.info(arg);
      } else {
        if(arg.indexOf('TBI tickBets,') !== -1) {
          // console.log(`%cElbetFastBalls: %c${arg} `, "color: #fdd; font-size:9px;text-shadow: 2px 2px 4px #0d6efd, 4px 4px 4px #d63384;");
        }
      }
    }

    this.gameServer = config.gameServers[Math.floor(Math.random() * config.gameServers.length)];
    this.chatServer = config.chatServer;
    this.sid = null;
    this.transportInitialized = false;
    this.platformParams = platformParams;
    this.gameStateServerSubscription = null;
    this.serverKeepaliveTimeout = serverKeepaliveTimeout;
    this.conn = null;
    this.chat = [];
    this.chatConn = null;
    // test
    this.updateNewMsg = 0;

    this.lastGameStateSrvMsgTs = 0;
    this.allLoginData = null;
    this.lastCashoutTsPerTicketId = {};
    this.lastBetTsPerButtonId = {};
    this.waitingBetAckButtonIds = [];

    this.JackPotValuesNames = [];

    this.dlog("CONFIG: " + JSON.stringify(config));
    this.dlog("platformParams: " + JSON.stringify(platformParams));

    this.conn = io(this.gameServer, {secure: true, transports: ['websocket'], upgrade: false});

    if(this.DEBUG) {
      this.conn.onAny((event, ...args) => {
        this.dlog(`SRVEVENT: ${event} ${JSON.stringify(args)}`);
      });
    }

    this.conn.on("connect_error", (err) => {
      console.log("connect_error");
      console.log(err.message)
      this.onDisconnect(err);
    });

    this.conn.on("connect", (data) => {
      if(storage.get("IsConnected") === true) return;
      storage.set("IsConnected", true);
      console.log(`%c Connected ${this.conn.connected}.`,
        "background-color:#000;color: #fff; font-size:15px;text-shadow: 1px 1px 4px;");
      

      this.connected()
      if(this.sid === null) {
        this.dlog("LOGIN ACTION INPUT ARG -> " + JSON.stringify(this.platformParams))
        this.conn.emit('login', this.platformParams)
      } else {
        this.dlog("LOGIN ACTION INPUT ARG-> " + this.platformParams)
        this.conn.emit('rememberme', this.sid)
      }
      this.transportInitialized = true;
    });

    this.conn.on("disconnect", (data) => {
      console.log("Event disconnect !")
      this.dlog("event disconnect");
      this.onDisconnect(data);
    });

    this.conn.on("login", (data) => {
      this.sid = data.sid;
      this.avatarId = data.player_data.avatar_id;
      this.allLoginData = JSON.parse(JSON.stringify(data));
      this.login(data);
      this.registerOnChatServer();
      this.conn.emit('rememberme', this.sid);
      if(this.DEBUG && window.location.href.includes("etdev")) {
        window.setWarning("sid: " + this.sid.toString() + " | srv_sig: " + data.srv_sig);
      }
    });
    this.assignGameEvents();
  }

  changeUsername = (newNick) => {
    console.log("changeUsername param =>", this.platformParams)
    this.platformParams.userDefinedUsername = newNick;
    this.conn.emit('login', this.platformParams);
  }

  // CHAT
  registerOnChatServer() {
    this.dlog("register on chat server");
		console.log("register on chat server");
    if(this.chatConn === null || this.chatConn.readyState !== 1) {
      if(!this.platformParams.chatRoom) {
        if(this.platformParams.companyId === 1) {
          this.platformParams.chatRoom = "demo";
        } else {
          if (this.allLoginData.launcher_params && this.allLoginData.launcher_params !== null) {
            this.platformParams.chatRoom = this.allLoginData.launcher_params.language;
          } else {
            this.platformParams.chatRoom = 'en';
          }
        }
      }

      // fix for en africans
      const enAf = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "ML", "MW", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RE", "RW", "ST", "SN", "SC", "SL", "SO", "ZA", "SS", "SD", "SZ", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"];
      if(this.platformParams.chatRoom.toString().toLowerCase() === "en" && this.allLoginData.player_data.country_code !== null &&
         enAf.includes(this.allLoginData.player_data.country_code.toString().toUpperCase())) {
        this.platformParams.chatRoom = "en-Af"
      }

      this.chatConn = new WebSocket(this.chatServer);
      this.chatConn.onopen = function(event) {

        let getUID = null;
        if (this.allLoginData.launcher_params && this.allLoginData.launcher_params.uid) {
          if (this.allLoginData.launcher_params.uid.indexOf('||') !== -1) {
            getUID = this.allLoginData.launcher_params.uid.split("||")[0];
          } else {
            getUID = this.allLoginData.launcher_params.uid;
          }
        }


        var registerString = JSON.stringify({
          method: 'REGISTER',
          company_id: this.platformParams.companyId || this.allLoginData.launcher_params.companyId,
          chat_room: this.platformParams.chatRoom,
          sid: this.sid,
          uid: this.platformParams.uid || getUID || String(new Date().valueOf()),
          display_name: this.allLoginData.player_data.username,
          country_code: this.allLoginData.player_data.country_code,
          language: this.platformParams.language || this.allLoginData.launcher_params.language,
          avatar_id: this.avatarId
        });

        this.dlog(registerString)
        this.chatConn.send(registerString);
        this.requestTotalOnline()
      }.bind(this);
      this.chatConn.onmessage = function(event) {
        let data = JSON.parse(event.data);
        this.dlog("chat_data " + event.data)
        switch(data.type) {
          case 'chat_like':
            let chatMsg = this.chat.find(m => m.id === data.message_id)
            if(chatMsg.likes_who.includes(data.who)) {
              chatMsg.likes_who = chatMsg.likes_who.filter(who => who !== data.who)
            } else {
              chatMsg.likes_who.push(data.who)
            }
            chatMsg['likes'] = parseInt(chatMsg['likes_who'].length)
            // abs value of this.updateChat is not important!
            this.updateChat = !this.updateChat;
            break;
          case 'chat_message':
            data.message.newMsg = true;
            this.chat.unshift(data.message);
            if(this.chat.length > 100) {
              this.chat.slice(0, 100);
            }
            if(data.total_online) {
              this.totalOnline(data.total_online);
            }
            // abs value of this.updateChat is not important!
            this.updateChat = !this.updateChat;

            this.updateNewMsg += 1;
            break;
          case 'chat_history':
            this.chat = data.messages.reverse()
            // console.log("WHAT is this.chat ", this.chat)
            for(const chatMsg of this.chat) {
              chatMsg['likes'] = parseInt(chatMsg['likes_who'].length)
              this.updateNewMsg += 1;
            }
            this.updateChat = !this.updateChat;
            break;

          case 'remchat':
            this.chat = this.chat.filter(item => item.id.toString() !== data["id"].toString());
            break;
          case 'total_online':
            this.totalOnline(data["users"])
            break;
          default:
            console.error(
              "unsupported event", data);
        }
        this.chatUpdate(this.chat)
      }.bind(this);
      this.chatConn.onclose = function(event) {
				// console.log('[ONCLOSE-chat]')
				setTimeout(() => {this.registerOnChatServer()}, 30000)
      }.bind(this);
    }
  }

  requestTotalOnline() {
    this.dlog("requestTotalOnline")
    if(this.chatConn.readyState === 1) this.chatConn.send(JSON.stringify({method: 'TOTAL_ONLINE', chat_room: this.platformParams.chatRoom}));
  }

  requestChatLike(msg_id = -1) {
    this.dlog("requestChatLike")
    if(this.chatConn.readyState === 1) this.chatConn.send(JSON.stringify({method: 'LIKE', chat_room: this.platformParams.chatRoom, msg_id: msg_id}));
  }

  requestSendChatMessage(msg = "") {
    this.dlog("requestSendChatMessage " + msg.toString())
    if(this.chatConn.readyState === 1) this.chatConn.send(JSON.stringify({method: 'MSG', chat_room: this.platformParams.chatRoom, text: msg}));
  }

  // GAME SERVER
  assignGameEvents() {
    this.conn.on("game_state", (data) => {if(this.transportInitialized) this.gameStateUpdate(data)});
    this.conn.on("bet_ack", (data) => {
      this.betAck(data)
      this.waitingBetAckButtonIds = this.waitingBetAckButtonIds.filter(id => id !== data["bet_ack"]["bet_button_id"]);
    });
    this.conn.on("bet_cancel_ack", (data) => {this.betCancelAck(data)});
    this.conn.on("cashout_ack", (data) => {this.cashoutAck(data)});
    this.conn.on("play_free_tickets_ack", (data) => {this.playedFreeTickets(data)});
    this.conn.on("tick_bets", (data) => {this.tickBets(data)});
    this.conn.on("tick_canceled_bets", (data) => {this.tickCanceledBets(data)});
    this.conn.on("tick_cashouts", (data) => {this.tickCashouts(data)});
    this.conn.on("exception", (data) => {
      this.exception(data)
      // FIX: can't bet after exception
      setTimeout(function() {
        try {
          this.waitingBetAckButtonIds = [];
        } catch {}
      }.bind(this), 1000)
    });
    this.conn.on("round_hash", (data) => {this.roundHashData(data)});
    this.conn.on("round_mp_by_hash", (data) => {this.roundMpByHash(data)});
    this.conn.on("jackpot_data", (data) => {this.jackpotData(data)});
    this.conn.on("player_bonus_history", (data) => {this.playerBonusHistory(data)});
    this.conn.on("jackpot_history_data", (data) => {this.jackpotHistory(data)});
    this.conn.on("direct_message", (data) => {this.notification(data)});
    this.conn.on("session_status", (data) => {this.sessionStatus(data)});
    this.conn.on("ticket_details", (data) => {this.ticketDetails(data)});
    this.conn.on("user_balance", (data) => {this.userBalance(data)});
    this.conn.on("bet_wins", (data) => {
      const top_type = data['top_type'];
      const days_back = data['days_back'];
      const bet_wins = data['bet_wins'];
      if(top_type === 1 && days_back === 7) this.topWinsWeekly(bet_wins);
      if(top_type === 1 && days_back === 30) this.topWinsWeekly(bet_wins);
      if(top_type === 1 && days_back === 365) this.topWinsWeekly(bet_wins);
      // if(top_type === 1 && days_back === 30) this.topWinsMonthly(bet_wins)
      // if(top_type === 1 && days_back === 365) this.topWinsYearly(bet_wins)

      if(top_type === 2 && days_back === 7) this.topOddsWeekly(bet_wins)
      if(top_type === 2 && days_back === 30) this.topOddsWeekly(bet_wins)
      if(top_type === 2 && days_back === 365) this.topOddsWeekly(bet_wins)

      if(top_type === 3 && days_back === 7) this.biggestOddsWeekly(bet_wins)
      if(top_type === 3 && days_back === 30) this.biggestOddsMonthly(bet_wins)
      if(top_type === 3 && days_back === 365) this.biggestOddsYearly(bet_wins)
    });
  }

  onDisconnect(e) {
    console.log('Disconnected')
  }

  disconnect() {
    this.dlog("disconnect")
    this.conn.disconnect()
  }

  placeBet(amount, betButtonId) {
    this.dlog("placeBet")
    this.lastCashoutTsPerTicketId = {}
    if(betButtonId in this.lastBetTsPerButtonId && Date.now() - this.lastBetTsPerButtonId[betButtonId] < 900) {
      this.dlog("BLOCKED BET SPAM (SAME REQUEST SENT WITHIN LAST 900ms)")
      return
    }
    if(this.waitingBetAckButtonIds.includes(betButtonId)) {
      this.dlog("BLOCKED DOUBLE SPENDING (WAITING FOR PREVIOUS BET ACK)")
      return
    }
    this.waitingBetAckButtonIds.push(betButtonId);
    this.lastBetTsPerButtonId[betButtonId] = Date.now()
    // console.log('no way !!')
    this.conn.emit('placed_bet', {"sid": this.sid, "bet_amount": amount, "bet_button_id": betButtonId})
  }

  cancelBet(ticketId) {
    this.dlog("cancelBet")
    this.conn.emit('canceled_bet', {"sid": this.sid, "ticket_id": ticketId})
  }

  cashout(ticketId, mp, is_autocashout) {
    this.dlog("cashout")
    this.lastBetTsPerButtonId = {}
    if(ticketId in this.lastCashoutTsPerTicketId && Date.now() - this.lastCashoutTsPerTicketId[ticketId] < 300) {
      this.dlog("BLOCKED CASHOUT SPAM (SAME REQUEST SENT WITHIN LAST 300ms)")
      return
    }
    this.lastCashoutTsPerTicketId[ticketId] = Date.now()
    this.conn.emit('cashout', {"sid": this.sid, "mp": mp, "ticket_id": ticketId, "is_autocashout": is_autocashout})
  }

  playFreeTickets() {
    this.dlog("playFreeTickets")
    this.conn.emit('play_free_tickets', {"sid": this.sid})
  }

  requestJackpotData() {
    const companyId = this.platformParams.companyId || this.allLoginData.launcher_params.companyId;
    
    if (this.sid !== null) {
        this.dlog("requestJackpotData");
        this.conn.emit('jackpot_data', { "sid": this.sid, "company_id": companyId });
    }
}


  requestPlayerBonusHistory() {
    if(this.sid !== null) {
      this.dlog("requestPlayerBonusHistory")
      this.conn.emit('player_bonus_history', {"sid": this.sid})
    }
  }

  requestUserBalance() {
    if(this.sid !== null) {
      this.dlog("requestUserBalance")
      this.conn.emit('get_user_balance', {"sid": this.sid})
    }
  }

  requestJackpotHistory(level) {
    this.dlog("requestJackpotHistory")
    this.conn.emit('jackpot_history', {"sid": this.sid, "level": level})
  }

  requestRoundHash(round_id = -1, ticket_id = -1) {
    this.dlog("requestRoundHash")
    this.conn.emit('round_hash', {"sid": this.sid, "round_id": round_id, "ticket_id": ticket_id})
  }

  requestRoundMpByHash(round_hash = "") {
    this.dlog("requestRoundMpByHash")
    this.conn.emit('mp_from_hash', {"sid": this.sid, "round_hash": round_hash})
  }

  requestTicketDetails(ticket_id = "") {
    this.dlog("requestTicketDetails")
    this.conn.emit('ticket_details', {"sid": this.sid, "ticket_id": ticket_id})
  }

  requestUpdateSettings(sound = false, music = false) {
    this.dlog("requestUpdateSettings")
		// console.log('requestUpdateSettings sound:', sound)
		// console.log('requestUpdateSettings music:', music)
    this.conn.emit('player_settings', {"sid": this.sid, "sound": sound, "music": music})
  }

  requestSetAvatarId(val) {
    console.log("new avatar id and login!!val!!!!", val)
    this.conn.emit('avatar_id', {"sid": this.sid, "avatar_id": val})
    // hc
    this.avatarId = val;
    // this.conn.emit('login', this.platformParams)
  }

  requestTopWinsWeekly() {
    this.dlog("requestTopWinsWeekly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_wins": {"days_back": 7}})
  }

  requestTopWinsMonthly() {
    this.dlog("requestTopWinsMonthly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_wins": {"days_back": 30}})
  }

  requestTopWinsYearly() {
    this.dlog("requestTopWinsYearly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_wins": {"days_back": 365}})
  }

  requestTopOddsWeekly() {
    this.dlog("requestTopOddsWeekly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_odds": {"days_back": 7}})
  }

  requestTopOddsMonthly() {
    this.dlog("requestTopOddsMonthly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_odds": {"days_back": 30}})
  }

  requestTopOddsYearly() {
    this.dlog("requestTopOddsYearly")
    this.conn.emit('bet_wins', {"sid": this.sid, "top_odds": {"days_back": 365}})
  }

  requestBiggestOddsWeekly() {
    this.dlog("requestBiggestOddsWeekly")
    this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds": {"days_back": 7}})
  }

  requestBiggestOddsMonthly() {
    this.dlog("requestBiggestOddsMonthly")
    this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds": {"days_back": 30}})
  }

  requestBiggestOddsYearly() {
    this.dlog("requestBiggestOddsYearly")
    this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds": {"days_back": 365}})
  }

  // TBI HANDLERS
  connected() {
    this.dlog(`TBI connected`)
  }

  exception(data) {
    this.dlog(`TBI exception, got ${JSON.stringify(data)}`)
    console.error(JSON.stringify(data['desc']))
  }

  notification(data) {
    this.dlog(`TBI notification, got ${JSON.stringify(data)}`)
  }

  login(data) {
    this.dlog(`TBI login, got ${JSON.stringify(data)}`)
  }

  userBalance(data) {
    this.dlog(`TBI userBalance, got ${JSON.stringify(data)}`)
  }

  playedFreeTickets(data) {
    this.dlog(`TBI playedFreeTickets, got ${JSON.stringify(data)}`)
  }

  sessionStatus(data) {
    this.dlog(`TBI sessionStatus, got ${JSON.stringify(data)}`)
  }

  betAck(data) {
    this.dlog(`TBI betAck, got ${JSON.stringify(data)}`)
  }

  betCancelAck(data) {
    this.dlog(`TBI betCancelAck, got ${JSON.stringify(data)}`)
  }

  cashoutAck(data) {
    this.dlog(`TBI cashoutAck, got ${JSON.stringify(data)}`)
  }

  gameStateUpdate(data) {
    // this.dlog(`TBI gameStateUpdate, got ${JSON.stringify(data)}`)
  }

  tickBets(data) {
    this.dlog(`TBI tickBets, got ${JSON.stringify(data)}`)
  }

  tickCanceledBets(data) {
    this.dlog(`TBI tickCanceledBets, got ${JSON.stringify(data)}`)
  }

  tickCashouts(data) {
    this.dlog(`TBI tickCashouts, got ${JSON.stringify(data)}`)
  }

  totalOnline(data) {
    this.dlog(`TBI totalOnline ${JSON.stringify(data)}`)
  }

  jackpotData(data) {
    this.dlog(`TBI jackpotData ${JSON.stringify(data)}`)
  }

  playerBonusHistory(data) {
    this.dlog(`TBI playerBonusHistory ${JSON.stringify(data)}`)
  }

  jackpotHistory(data) {
    this.dlog(`TBI jackpotHistory ${JSON.stringify(data)}`)
  }

  roundMpByHash(data) {
    this.dlog(`TBI roundMpByHash ${JSON.stringify(data)}`)
  }

  ticketDetails(data) {
    this.dlog(`TBI ticketDetails ${JSON.stringify(data)}`)
  }

  roundHashData(data) {
    this.dlog(`TBI roundHashData ${JSON.stringify(data)}`)
  }

  chatUpdate(data) {
    // this.dlog(`TBI chatUpdate ${JSON.stringify(data)}`)
  }

  topWinsWeekly(data) {
    this.dlog(`TBI topWinsWeekly, got ${JSON.stringify(data)}`)
  }

  topWinsMonthly(data) {
    this.dlog(`TBI topWinsMonthly, got ${JSON.stringify(data)}`)
  }

  topWinsYearly(data) {
    this.dlog(`TBI topWinsYearly, got ${JSON.stringify(data)}`)
  }

  topOddsWeekly(data) {
    this.dlog(`TBI topOddsWeekly, got ${JSON.stringify(data)}`)
  }

  topOddsMonthly(data) {
    this.dlog(`TBI topOddsMonthly, got ${JSON.stringify(data)}`)
  }

  topOddsYearly(data) {
    this.dlog(`TBI topOddsYearly, got ${JSON.stringify(data)}`)
  }

  biggestOddsWeekly(data) {
    this.dlog(`TBI biggestOddsWeekly, got ${JSON.stringify(data)}`)
  }

  biggestOddsMonthly(data) {
    this.dlog(`TBI biggestOddsMonthly, got ${JSON.stringify(data)}`)
  }

  biggestOddsYearly(data) {
    this.dlog(`TBI biggestOddsYearly, got ${JSON.stringify(data)}`)
  }
}

export default TransportAbstract;