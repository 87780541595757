import {Howl} from 'howler';

class SoundEffect {

  setNet(net) {this.net = net;}

  constructor() {
    this.net = null;
    this.sound_files = {
      random6: new Howl({
        src: ['sound-music/random6.mp3']
      }),
      ticket_clear: new Howl({
        src: ['sound-music/ticket_clear.mp3']
      }),
      countdown: new Howl({
        src: ['sound-music/countdown.mp3']
      }),
      all_bets_list_appear_1: new Howl({
        src: ['sound-music/all_bets_list_appear_1.mp3']
      }),
      all_bets_list_appear_2: new Howl({
        src: ['sound-music/all_bets_list_appear_2.mp3']
      }),
      betBTN: new Howl({
        src: ['sound-music/betBTN.mp3']
      }),
      cancelBTN: new Howl({
        src: ['sound-music/cancelBTN.mp3']
      }),
      cashout_1: new Howl({
        src: ['sound-music/cashout_1.mp3']
      }),
      cashout_2: new Howl({
        src: ['sound-music/cashout_2.mp3']
      }),
      cashout_close: new Howl({
        src: ['sound-music/cashout_close.mp3']
      }),
      chat_appear_1: new Howl({
        src: ['sound-music/chat_appear_1.mp3']
      }),
      chat_appear_2: new Howl({
        src: ['sound-music/chat_appear_2.mp3']
      }),
      checking_for_spam: new Howl({
        src: ['sound-music/checking_for_spam.mp3']
      }),
      controlBTN: new Howl({
        src: ['sound-music/controlBTN.mp3']
      }),
      decrease_amount_BTN: new Howl({
        src: ['sound-music/decrease_amount_BTN.mp3']
      }),
      increase_amount_BTN: new Howl({
        src: ['sound-music/increase_amount_BTN.mp3']
      }),
      final_payout_1: new Howl({
        src: ['sound-music/final_payout_1.mp3']
      }),
      final_payout_2: new Howl({
        src: ['sound-music/final_payout_2.mp3']
      }),
      final_payout_3: new Howl({
        src: ['sound-music/final_payout_3.mp3']
      }),
      activeticket_win: new Howl({
        src: ['sound-music/activeticket_win.mp3']
      }),
      menu_switch_1: new Howl({
        src: ['sound-music/menu_switch_1.mp3']
      }),
      tabs: new Howl({
        src: ['sound-music/tabs.mp3']
      }),
      num_appear: new Howl({
        src: ['sound-music/num_appear.mp3']
      }),
      posting: new Howl({
        src: ['sound-music/posting.mp3']
      }),
      promo_credit_1: new Howl({
        src: ['sound-music/promo_credit_1.mp3']
      }),
      shuffle: new Howl({
        src: ['sound-music/shuffle.mp3']
      }),
      on_bonus: new Howl({
        src: ['sound-music/on_bonus.mp3']
      }),
      screen_transition_1: new Howl({
        src: ['sound-music/screen_transition_1.mp3']
      }),
      screen_transition_2: new Howl({
        src: ['sound-music/screen_transition_2.mp3']
      }),
      screen_transition_outro: new Howl({
        src: ['sound-music/screen_transition_outro.mp3']
      }),
      scroll_1: new Howl({
        src: ['sound-music/scroll_1.mp3']
      }),
      scroll_2: new Howl({
        src: ['sound-music/scroll_2.mp3']
      }),
      selectBTN_1: new Howl({
        src: ['sound-music/selectBTN_1.mp3']
      }),
      selectBTN_2: new Howl({
        src: ['sound-music/selectBTN_2.mp3']
      }),
      set_autoplay: new Howl({
        src: ['sound-music/set_autoplay.mp3']
      }),
      set_auto_click_sound: new Howl({
        src: ['sound-music/set_auto_click_sound.mp3']
      }),
      slider: new Howl({
        src: ['sound-music/slider.mp3']
      }),
      texting_1: new Howl({
        src: ['sound-music/texting_1.mp3']
      }),
      texting_2: new Howl({
        src: ['sound-music/texting_2.mp3']
      }),
      you_have_cashed_out_close: new Howl({
        src: ['sound-music/you_have_cashed_out_close.mp3']
      })
    };

    this.hidden = "";
      if(typeof document.hidden !== "undefined") {
        this.hidden = "hidden";
      } else if(typeof document.msHidden !== "undefined") {
        this.hidden = "msHidden";
      } else if(typeof document.webkitHidden !== "undefined") {
        this.hidden = "webkitHidden";
      }

    for ( let key in this.sound_files) {
      this.sound_files[key].volume(0.1);
    }
  }

  play(soundName) {
    if(this.net.sound && !document[this.hidden]) {
      this.sound_files[soundName].play();
    }
  }

  stop(soundName) {
    this.sound_files[soundName].stop();
  }

  volume(soundName, level) {
    this.sound_files[soundName].volume(level);
  }

  allVolume(level) {
    for (let key in this.sound_files) {
      this.sound_files[key].volume(level);
    }
  }


  loop(soundName, val) {
    this.sound_files[soundName].loop(val);
  }

  fade(soundName, from, to, duration) {
    this.sound_files[soundName].fade(from, to, duration);
  }

}

const soundeffects = new SoundEffect();
window.soundeffects = soundeffects;
export default soundeffects;
