/* eslint-disable eqeqeq */
import React, {useEffect, useState} from "react";
import label from "../../services/Translation/lang";
// import FadeIn from "react-fade-in";
// import { getAvatar } from "../../model/avatars";
// import Flag from "react-world-flags";
import Statsbar from "../NumbersAnim/slot";
import soundEffects from "../../services/Sounds/SoundFxPlayer";
import {getGamePlayParams} from "../../services/UrlParamsLoader/UrlParamsLoader";
import JackpotTableTr from "./JackpotTableTr";
import storage from "../../services/Storage/Storage";
import {currencyFormatter} from "../../services/Helpers/NumberFormatter";
import moment from 'moment';

let invertor = true;
let frequency = 3, counterF = 0;


const Jackpot = (props) => {
	const [jackPotValuesLocal, setJackPotValuesLocal] = useState(null);
	const [fakeTimer, setFakeTimer] = useState(null);
	const [myFormat, setMyFormat] = useState('(,ddd).dd');

	useEffect(() => {
		if(sessionStorage.getItem('amountFormat') === '###,###') {
			setMyFormat("(,ddd)");
		} else {
			if(props.net.noOfDecimals == "0") setMyFormat("(,ddd)");
			if(props.net.noOfDecimals == "1") setMyFormat("(,ddd).d");
			if(props.net.noOfDecimals == "2") setMyFormat("(,ddd).dd");
			if(props.net.noOfDecimals == "3") setMyFormat("(,ddd).ddd");
			if(props.net.noOfDecimals == "4") setMyFormat("(,ddd).dddd");
			if(props.net.noOfDecimals == "5") setMyFormat("(,ddd).ddddd");
			if(props.net.noOfDecimals == "6") setMyFormat("(,ddd).dddddd");
		}
	}, [props.net, props.net.noOfDecimals, props.JPUpdate, myFormat, setMyFormat]);

	useEffect(() => {

		if(sessionStorage.getItem('amountFormat') === '###,###') {
			setMyFormat("(,ddd)");
		} else {
			if(props.net.noOfDecimals == "0") setMyFormat("(,ddd)");
			if(props.net.noOfDecimals == "1") setMyFormat("(,ddd).d");
			if(props.net.noOfDecimals == "2") setMyFormat("(,ddd).dd");
			if(props.net.noOfDecimals == "3") setMyFormat("(,ddd).ddd");
			if(props.net.noOfDecimals == "4") setMyFormat("(,ddd).dddd");
			if(props.net.noOfDecimals == "5") setMyFormat("(,ddd).ddddd");
			if(props.net.noOfDecimals == "6") setMyFormat("(,ddd).dddddd");
		}

		setJackPotValuesLocal(props.net.JackPotValues);
		if(fakeTimer === null && typeof props.net.JackPotValuesNames !== "undefined") {
			setFakeTimer(setInterval(() => {
				var local = props.net.JackPotValues;
				var testDelta = 0.01;
				if(storage.get('userCurrency') !== 'EUR') {testDelta = 0.0002}
				for(var key in local) {// ~ 0.00055 4x
					if(invertor === true) {
						local[key] = local[key] + testDelta;
						counterF++;
						if(counterF > frequency) {
							invertor = false
							counterF = 0;
						}
					} else {
						// local[key] = local[key] - 0.01;
						counterF++;
						if(counterF > frequency) {
							invertor = true;
							counterF = 0;
						}
					}
					// local[key] = local[key] + local[key] * 0.00055;
					var indexOfDot = local[key].toString().indexOf(".");
					indexOfDot = indexOfDot + 2;
					// eslint-disable-next-line eqeqeq
					if(local[key].toString()[indexOfDot] == 0 || local[key].toString()[indexOfDot] == "0") {
						const returnedTarget = Object.assign({}, local[key].toString());
						returnedTarget[indexOfDot] = 1;
						var T = ``;
						for(let j = 0;j <= indexOfDot;j++) {
							T += returnedTarget[j];
						}
						local[key] = parseFloat(T);
					}
				}
				setJackPotValuesLocal(local);
			}, getGamePlayParams().JPINTERVAL)
			);
		}
	}, [
		props.net.noOfDecimals,
		fakeTimer,
		props.JP1MenuVisibility,
		props.net.JackPotValuesNames,
		props.net.JackPotValues,
		props.JPUpdate,
	]);

	if(
		typeof props.net.JackPotValuesNames !== "undefined" &&
		jackPotValuesLocal !== null
	) {
		return (
			<div
				className="jackpot-wrapper"
			>
				<div className="jackpots-holder">
					{props.net.JackPotValuesNames.length >= 1 ? (
						<div className="jackpot-btn-holder">
							<div className="jackpot-btn-title">
								{props.net.JackPotValuesNames[0]}
							</div>
							<button
								className="jackpot-btn"
								onClick={() => {
									label.update();
									soundEffects.play("tabs");
									props.setJP1MenuVisibility(true);
								}}
							>
								<div className="jackpot-value-holder">
									{props.net.JackPotValuesNames.length > 0 ? (
										<>
											<Statsbar
												// format="(,ddd).dd" duration={15000} /**animation='count'*/
												format={myFormat} duration={15000} /**animation='count'*/
												value={
													parseFloat(jackPotValuesLocal[props.net.JackPotValuesNames[0]]) * parseFloat(props.net.myExchangeRate.toFixed(props.net.noOfDecimals))
												}
											/>
											<span className="jackpot-currency">
												{props.userCurrencyCode}{" "}
											</span>
										</>
									) : null}
								</div>
							</button>

							<div
								style={{
									display: props.JP1MenuVisibility === true ? "block" : "none",
								}}
								data-jackpotmenu="true"
								className={"jackpot-info-holder rocketpot-info-holder "}
							>
								<div className="jackpot-info-title">
									<div>
										<span style={{width: "min-content"}} data-langname="history"></span> {props.net.JackPotValuesNames[0]}
									</div>
									<button
										className="close-container bet-info-close"
										onClick={() => props.setJP1MenuVisibility(false)}
									>
										<div className="leftright"></div>
										<div className="rightleft"></div>
									</button>
								</div>
								<table className="bets-table my-bets-table">
									<thead>
										<tr className="table-header">
											<th>{label.t("date")}</th>
											<th>{label.t("time")}</th>
											<th>{label.t("win")}</th>
											<th>{label.t("user")}</th>
										</tr>
									</thead>
									<tbody>
										{props.JP1History.map((history, i) => {
											return (
												<JackpotTableTr
													key={i}
													date={history.jackpot_date}
													time={moment.utc(`${history.jackpot_date} ${history.jackpot_time}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')}
													jackpotWinAmount={Number(
														Number(history.jackpot_value_eur) *
														Number(props.net.myExchangeRate)
													).toFixed(props.net.noOfDecimals)}
													username={history.username}
													flagCode={history.country_code}
													avatar={history.avatar_id}
												/>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					) : null}

					{props.net.JackPotValuesNames.length >= 2 ? (
						<div className="jackpot-btn-holder">
							<div className="jackpot-btn-title">
								{props.net.JackPotValuesNames[1]}
							</div>
							<button
								className="jackpot-btn"
								onClick={() => {
									label.update();
									soundEffects.play("tabs");
									props.setJP2MenuVisibility(true);
								}}
							>
								<div className="jackpot-value-holder">
									{props.net.JackPotValuesNames.length > 0 ? (
										<>
											<Statsbar
												format={myFormat} duration={15000} /**animation='count'*/
												// format="(,ddd).dd" /**  duration={15000} animation='count'*/
												value={
													parseFloat(
														jackPotValuesLocal[props.net.JackPotValuesNames[1]]
													) *
													parseFloat(
														props.net.myExchangeRate.toFixed(
															props.net.noOfDecimals
														)
													)
												}
											/>
											<span className="jackpot-currency">
												{props.userCurrencyCode}{" "}
											</span>
										</>
									) : null}
								</div>
							</button>
							<div
								style={{
									display: props.JP2MenuVisibility === true ? "block" : "none",
								}}
								data-jackpotmenu="true"
								className={"jackpot-info-holder rocketpot-info-holder "}
							>
								<div className="jackpot-info-title">
									<div>
										<span style={{width: "min-content"}} data-langname="history"></span> {props.net.JackPotValuesNames[1]}
									</div>
									<button
										className="close-container bet-info-close"
										onClick={() => props.setJP2MenuVisibility(false)}
									>
										<div className="leftright"></div>
										<div className="rightleft"></div>
									</button>
								</div>
								<table className="bets-table my-bets-table">
									<thead>
										<tr className="table-header">
											<th>{label.t("date")}</th>
											<th>{label.t("time")}</th>
											<th>{label.t("win")}</th>
											<th>{label.t("user")}</th>
										</tr>
									</thead>
									<tbody>
										{props.JP2History.map((history, i) => {
											return (
												<JackpotTableTr
													key={i}
													date={history.jackpot_date}
													time={moment.utc(`${history.jackpot_date} ${history.jackpot_time}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')}
													jackpotWinAmount={Number(
														Number(history.jackpot_value_eur) *
														Number(props.net.myExchangeRate)
													).toFixed(props.net.noOfDecimals)}
													username={history.username}
													flagCode={history.country_code}
													avatar={history.avatar_id}
												/>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					) : null}

					{props.net.JackPotValuesNames.length >= 3 ? (
						<div className="jackpot-btn-holder">
							<div className="jackpot-btn-title">
								{props.net.JackPotValuesNames[2]}
							</div>
							<button
								className="jackpot-btn"
								onClick={() => {
									soundEffects.play("tabs");
									props.setJP3MenuVisibility(true);
								}}
							>
								<div className="jackpot-value-holder">
									{props.net.JackPotValuesNames.length > 0 ? (
										<>
											<Statsbar
												format={myFormat} duration={15000} /**animation='count'*/
												// format="(,ddd).dd" /**  duration={15000} animation='count'*/
												value={
													parseFloat(
														jackPotValuesLocal[props.net.JackPotValuesNames[2]]
													) *
													parseFloat(
														props.net.myExchangeRate.toFixed(
															props.net.noOfDecimals
														)
													)
												}
											/>
											<span className="jackpot-currency">
												NIK{props.userCurrencyCode}{" "}
											</span>
										</>
									) : null}
								</div>
							</button>
							<div
								style={{
									display: props.JP3MenuVisibility === true ? "block" : "none",
								}}
								data-jackpotmenu="true"
								className={"jackpot-info-holder rocketpot-info-holder "}
							>
								<div className="jackpot-info-title">
									<div>
										<span style={{width: "min-content"}} data-langname="history"></span> {props.net.JackPotValuesNames[2]}
									</div>
									<button
										className="close-container bet-info-close"
										onClick={() => props.setJP3MenuVisibility(false)}
									>
										<div className="leftright"></div>
										<div className="rightleft"></div>
									</button>
								</div>
								<table className="bets-table my-bets-table">
									<thead>
										<tr className="table-header">
											<th>{label.t("date")}</th>
											<th>{label.t("time")}</th>
											<th>{label.t("win")}</th>
											<th>{label.t("user")}</th>
										</tr>
									</thead>
									<tbody>
										{props.JP3History.map((history, i) => {
											return (
												<JackpotTableTr
													key={i}
													date={history.jackpot_date}
													time={moment.utc(`${history.jackpot_date} ${history.jackpot_time}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')}
													jackpotWinAmount={Number(
														Number(history.jackpot_value_eur) *
														Number(props.net.myExchangeRate)
													).toFixed(props.net.noOfDecimals)}
													username={history.username}
													flagCode={history.country_code}
													avatar={history.avatar_id}
												/>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Jackpot;
